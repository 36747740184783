import "./Mine.css";
import { MyCollection } from "../components/MyCollection";

export function Collection() {
  return (
    <div className="page">
      <div className="">
        <MyCollection></MyCollection>
      </div>
    </div>
  );
}
