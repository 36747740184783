import "./Stats.css";
import { Ranking } from "../components/Ranking.js";

export function Stats() {
  return (
    <div className="page">
      <div className="">
        <Ranking></Ranking>
      </div>
    </div>
  );
}
